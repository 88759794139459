<template>
  <div>
    <b-overlay
      :show="showOverlay"
    >
      <div class="custom-search">
        <!-- advance search input -->
        <b-row v-if="detail">
          <b-col cols="2">
            <br>
            <b-modal-ingreso-ticket @refrescar-pagina="loadReport" />
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Tipo de Falla:</label>
              <v-select
                v-model="tipoFalla"
                label="nombre"
                :options="tiposFallas"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Etapa:</label>
              <v-select
                v-model="etapaBusqueda"
                label="title"
                :options="etapas"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Fecha Inicio:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="fechaInicio"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaInicio"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Fecha Fin:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="fecha-fin"
                  v-model="fechaFin"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaFin"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <br>
            <table>
              <tr>
                <td style="padding-top: 4px;">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.v-primary
                    class="btn-icon"
                    title="Buscar"
                    variant="gradient-primary"
                    @click="loadReport()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </td>
                <td style="padding-top: 4px;">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    v-b-tooltip.hover.v-success
                    variant="success"
                    placeholder="Asignar"
                    title="Asignar Tecnico"
                    class="btn-icon"
                    @click="asignarTickets()"
                  >
                    <feather-icon icon="UserCheckIcon" />
                  </b-button>
                </td>
                <td style="padding-top: 4px;">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.v-danger
                    title="Anular"
                    variant="danger"
                    class="btn-icon"
                    @click="anularTickets()"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </td>
                <td
                  v-if="usuario.company.enabledReport"
                  style="padding-top: 4px;"
                >
                  <reporte-tickets
                    :tickets-data="ticketsData"
                    :origen="usuario"
                  />
                </td>
              </tr>
            </table>

          </b-col>
        </b-row>
        <!-- table -->
        <br>
        <b-row>
          <b-col cols="12">
            <!-- table -->
            <vue-good-table
              :columns="columnasTickets || columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
              :search-options="{
                enabled: true }"
              style-class="vgt-table condensed"
              :select-options="selectOptions()"
              @on-select-all="onSelectAll"
              @on-selected-rows-change="selectionChanged"
              @on-row-dblclick="onRowClick"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      v-if="props.row.estado !=='ANULADO' && props.row.estado !== 'FINALIZADA'"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="canAsignar(props.row)"
                        @click="showAsignar(props)"
                      >
                        <feather-icon
                          icon="UserIcon"
                          class="mr-50"
                        />
                        <span>{{ props.row.estado === 'VERIFICADO'? 'Asignar' : 'Reasignar' }} </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.estado === 'CONFIRMACION'"
                        @click="confirmarTicket(props)"
                      >
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                        />
                        <span>Confirmar Ticket</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="canAnular(props.row)"
                        @click="anularTicket(props)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                        <span>Anular Ticket</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.estado === 'FINALIZADA'"
                        @click="descargarReporte(props)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                        <span>Descargar Reporte</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <span
                  v-else-if="props.column.field === 'horasAtencion'"
                  class="text-nowrap"
                >
                  <b-badge
                    v-if="props.row.estado !== 'ANULADO'"
                    pill
                    :variant="`light-${getStatusClass(props.row, 'A')}`"
                    :class="`text-${getStatusClass(props.row, 'A')}`"
                  >
                    {{ getTiempoHoras(props.row,'A') }}
                  </b-badge>
                </span>
                <span
                  v-else-if="props.column.field === 'fuente.nombre'"
                  class="text-nowrap"
                >
                  <b-badge
                    style="width: 130px;"
                    :variant="getColor(props.row.fuente ? props.row.fuente.nombre : '')"
                  >
                    {{ props.row.fuente ? props.row.fuente.nombre : '' }}
                  </b-badge>
                </span>
                <span
                  v-else
                  style="font-size: 13px"
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Mostrar
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="value => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> de {{ props.total }} registros </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="value => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

      <!---- MODALES ---->
      <b-modal
        v-model="dialog"
        centered
        ok-title="Aceptar"
        ok-only
        size="lg"
        :title="`Detalle de Ticket No. ${falla.ticket}`"
        @hidden="loadReport()"
      >
        <detalle-ticket
          :ticket="falla"
          :punto="punto"
          :inspeccion="inspeccion"
          @cerrar-dialog="loadReport"
          @refrescar-tabla="loadReport"
          @actualizar-detalle="loadReport()"
          @actualizar="actualizarDetalle"
        />
      </b-modal>
      <b-modal
        ref="modal-tecnico"
        v-model="dialogAsigacion"
        title="Asignación de Técnico"
        :ok-title="getTitleOk()"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        @hidden="limpiarComentarios"
        @ok="handleOk"
      >
        <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Seleccione el Técnico Encargado"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="ticketAsignar.tecnicoEncargado"
              label="nombre"
              :options="tecnicos"
              required
            />
          </b-form-group>
          <div v-if="mostrarComentario">
            <b-form-group
              label="Justificación del Tiempo"
              label-for="comentarios"
            >
              <b-form-textarea
                id="comentarios"
                v-model="comentario"
                rows="2"
                required
              />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        v-model="verDialogoAnulacion"
        ok-only
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        ok-variant="secondary"
        ok-title="Cancelar"
        modal-class="modal-primary"
        centered
        title="Anular Ticket"
      >
        <b-card>
          <anular-ticket
            :ticket="ticketAnular"
            @cerrar-dialogo="cerrarAnulacion"
          />
        </b-card>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  VBModal,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DetalleTicket from '@/components/Tickets/DetalleTicket.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { calcularFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import {
  findTickets, getColorFuente, getHoras, getTicket, updateCreateTicket,
} from '@/utils/tickets'
import { getlistado } from '@/utils/catalogos'
import { findInspeccionId } from '@/utils/inspecciones'
import { getPoste } from '@/utils/postes'
import { getUsersByCompanyRole, getUsuario } from '@/utils/usuarios'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import BModalIngresoTicket from '@/components/Tickets/BModalIngresoTicket.vue'
import { getLocalidad } from '@/utils/localizacion'
import AnularTicket from '@/components/Tickets/AnularTicket.vue'
import { calcularTiempoTranscurrido } from '@/utils/funciones'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ReporteTickets from '@/utils/reportes/mantenimientoCorrectivo/reporteTickets.vue'

export default {
  name: 'Reporte',
  components: {
    ReporteTickets,
    BCard,
    BOverlay,
    BForm,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    DetalleTicket,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BModalIngresoTicket,
    AnularTicket,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  props: {
    detail: {
      type: Boolean,
      default: true,
    },
    columnasTickets: {
      type: Array,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    numeroPoste: {
      type: String,
    },
  },
  data() {
    return {
      ticketsData: [],
      verDialogoAnulacion: false,
      ticketAnular: {},
      tipoFalla: {
        nombre: 'Todas',
        value: '',
      },
      tiposFallas: [],
      etapaBusqueda: {
        title: 'Todas',
        value: '',
      },
      etapas: [
        { title: 'Todos', value: '' },
        { title: 'En Confirmación', value: 'CONFIRMACION' },
        { title: 'Pendientes', value: 'PENDIENTE' },
        { title: 'Verificados', value: 'VERIFICADO' },
        { title: 'Asignados', value: 'ASIGNADO' },
        { title: 'En Curso', value: 'EN CURSO' },
        { title: 'Finalizados', value: 'FINALIZADA' },
        { title: 'Anulados', value: 'ANULADO' },
      ],
      fechaInicio: '',
      fechaFin: '',
      rows: [],
      columns: [
        {
          label: 'No.',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Poste',
          field: 'punto.numeroPoste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Canal',
          field: 'canal',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fuente',
          field: 'fuente.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Falla',
          field: 'falla.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Etapa',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Ingreso',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Horas Atención',
          field: 'horasAtencion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      searchTerm: '',
      perPage: 5,
      totalRows: 1,
      currentPage: 1,
      pageLength: 15,
      dialog: false,
      dialogAsigacion: false,
      falla: {},
      punto: null,
      ticketAsignar: { tecnicoEncargado: null },
      inspeccion: null,
      tecnicos: [],
      nameState: null,
      isMasiveAsignation: false,
      isMasiveAnulation: false,
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      usuario: JSON.parse(localStorage.getItem('userData')),
      seleccionados: [],
      showOverlay: false,
      columnsReporte: [],
      rowsReporte: [],
      comentario: '',
      mostrarComentario: false,
    }
  },
  watch: {
    numeroPoste: {
      immediate: true,
      handler() {
        if (!this.detail) {
          this.loadReport()
        }
      },
    },
  },
  async beforeMount() {
    await this.cargarFallas()
  },
  async created() {
    this.rows = []
    await this.validarEmpresa()
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
    this.tecnicos.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
    this.showOverlay = false
  },
  methods: {
    async validarEmpresa() {
      if (this.usuario.company.name === 'APVN') {
        this.tecnicos = await getUsersByCompanyRole(this.usuario.idCompany, 'tecnico')
      } else if (this.usuario.company.name === 'APMIX') {
        this.tecnicos = await getUsersByCompanyRole(this.usuario.company.id, 'migracion')
      }
    },
    actualizarDetalle() {
      this.dialog = false
    },
    selectOptions() {
      return this.detail
        ? {
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }
        : { enabled: false }
    },
    canAsignar(row) {
      return row.estado === 'VERIFICADO' || row.estado === 'ASIGNADO' || row.estado === 'EN CURSO'
    },
    canAnular(row) {
      return row.estado !== 'FINALIZADA' && row.estado !== 'ANULADO'
    },
    asignarTickets() {
      if (this.seleccionados.length === 0) {
        this.mensajeError('No se han seleccionado tickets!!')
        return
      }
      this.isMasiveAsignation = true
      // eslint-disable-next-line prefer-destructuring
      this.ticketAsignar = this.seleccionados[0]
      this.comentario = ''
      let mostrarComentario = false

      Promise.all(this.seleccionados.map(item => getTicket(item.id))).then(tickets => {
        // eslint-disable-next-line no-restricted-syntax
        for (const ticket of tickets) {
          const tiempoCreacion = calcularTiempoTranscurrido(ticket)
          if (tiempoCreacion >= 60) {
            mostrarComentario = true
            break
          }
        }
        this.mostrarComentario = mostrarComentario

        this.dialogAsigacion = true
      })
    },
    anularTickets() {
      if (this.seleccionados.length === 0) {
        this.mensajeError('No se han seleccionado tickets!!')
        return
      }
      let haveErrors = false
      this.seleccionados.forEach(item => {
        if (!this.validarTicketMasivo('ANULAR', item)) {
          this.mensajeError(`El ticket ${item.ticket}, no puede anular ya que se encuentra en estado ${item.estado}`)
          haveErrors = true
        }
      })
      if (!haveErrors) {
        this.isMasiveAnulation = true
        this.anularTicket(this.seleccionados[0], true)
      }
    },
    validarTicketMasivo(type, ticket) {
      if (type === 'ASIGNAR') { return this.canAsignar(ticket) }
      if (type === 'ANULAR') { return this.canAnular(ticket) }
      return false
    },
    onSelectAll(params) {
      this.seleccionados = params.selectedRows
    },
    selectionChanged(params) {
      this.seleccionados = params.selectedRows
    },
    getStatusClass(row, type) {
      const horas = getHoras(row, type)
      if (horas < 6) return 'success'
      if (horas >= 6 && horas < 10) return 'secondary'
      if (horas >= 10 && horas < 12) return 'warning'
      return 'danger'
    },
    getTiempoHoras(row, type) {
      return getHoras(row, type)
    },
    getColor(row, type) {
      return getColorFuente(row, type)
    },
    checkFormValidity() {
      if (!this.ticketAsignar.tecnicoEncargado) return false
      return true
    },
    async chetComentarioValidity() {
      if (!this.comentario || this.comentario === '') {
        this.mensajeError('Debe Ingresar el Comentario de Justificación de Tiempo!')
        return true
      }
      return false
    },
    cerrarModal() {
      this.$nextTick(() => {
        this.$refs['modal-tecnico'].toggle('#toggle-btn')
      })
    },
    mensajeError(mensaje) {
      Swal.fire({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    success(title, text) {
      Swal.fire({
        title,
        text,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        this.mensajeError('Debe de seleccionar un técnico!')
        return
      }
      if (this.debeIncluirJustificacionTiempo) {
        if (!this.comentario || this.comentario.trim() === '') {
          mensajeError('Debe Ingresar el Comentario de Justificación de Tiempo!')
          return
        }
      }
      await this.guardarAsinaciones()
    },
    async guardarAsinaciones() {
      this.showOverlay = true

      try {
        if (this.isMasiveAsignation) {
          let haveErrors = false
          // eslint-disable-next-line no-restricted-syntax
          for await (const item of this.seleccionados) {
            if (!this.validarTicketMasivo('ASIGNAR', item)) {
              this.mensajeError(`El ticket ${item.ticket}, no puede asignarse ya que se encuentra en estado ${item.estado}`)
              haveErrors = true
            } else {
              if (this.mostrarComentario) {
                if (!this.comentario || this.comentario.trim() === '') {
                  this.mensajeError('Debe Ingresar el Comentario de Justificación de Tiempo!')
                  this.showOverlay = false
                  return
                }
              }
              const ticket = await getTicket(item.id)
              const tiempoCreacion = calcularTiempoTranscurrido(ticket)
              this.mostrarComentario = tiempoCreacion >= 60
              await this.guardarAsignacion(ticket)
            }
          }
          if (!haveErrors) {
            this.success('Asignación Masiva', 'Se asignaron los tickets correctamente!!')
            await this.loadReport()
          }
        } else {
          await this.guardarAsignacion(this.ticketAsignar, this.mostrarComentario)
        }
      } catch (err) {
        console.error('Error asignando técnico', err)
      }

      this.isMasiveAsignation = false
      this.showOverlay = false
    },
    // eslint-disable-next-line consistent-return
    async guardarAsignacion(ticket) {
      try {
        const esReasignacion = ticket.estado === 'ASIGNADO'
        if (this.mostrarComentario) {
          if (!this.comentario || this.comentario.trim() === '') {
            this.mensajeError('Debe Ingresar el Comentario de Justificación de Tiempo!')
            return
          }
        }
        // eslint-disable-next-line no-param-reassign
        ticket.estado = 'ASIGNADO'
        const usuario = await getUsuario(this.ticketAsignar.tecnicoEncargado.id)
        // eslint-disable-next-line no-param-reassign
        ticket.tecnicoEncargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          name: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
          telefono: '',
        }
        // eslint-disable-next-line no-param-reassign
        const asignacion = {
          fechaHora: new Date(),
          localization: this.localization,
          tipo: 'Asignación Ticket',
          usuario: this.tecnicoEncargado,
        }

        if (this.mostrarComentario) {
          asignacion.comentarios = this.comentario
        }

        ticket.seguimiento.push(asignacion)
        const resultado = await updateCreateTicket(ticket, 1)
        if (esReasignacion) {
          mensajeInformativo(`Ticket ${ticket.ticket} ha sido reasignado correctamente!`)
        } else {
          mensajeInformativo(`Ticket ${ticket.ticket} ha sido asignado correctamente!`)
        }
        this.cerrarModal()
        if (resultado === null) {
          if (!this.isMasiveAsignation) {
            await this.loadReport()
          } else {
            this.mensajeError(`Ocurrió un problema actualizando el ticket ${ticket.ticket}`)
          }
        }
      } catch (err) {
        console.error('error asignando técnico', err)
      }
    },
    limpiarComentarios() {
      this.loadReport()
      this.comentario = ''
    },
    getTitleOk() {
      if (this.ticketAsignar && this.ticketAsignar.estado === 'ASIGNADO') {
        return 'Reasignación de Técnico'
      }
      return 'Asignación de Técnico'
    },
    showAsignar(params) {
      this.isMasiveAsignation = false
      getTicket(params.row.id).then(ticket => {
        if (ticket) {
          this.ticketAsignar = ticket
          this.dialogAsigacion = true

          const tiempoCreacion = calcularTiempoTranscurrido(ticket)
          this.mostrarComentario = tiempoCreacion >= 60
        }
      })
    },
    async cerrarDialogo() {
      this.dialog = false
      this.verDialogoAnulacion = false
      await this.loadReport()
    },
    async cerrarAnulacion() {
      if (this.isMasiveAnulation) {
        const ticketAnulado = getTicket(this.ticketAnular.id)
        // eslint-disable-next-line no-restricted-syntax
        for await (const item of this.seleccionados) {
          if (item.id !== ticketAnulado.id) {
            const ticket = await getTicket(item.id)
            ticket.estado = ticketAnulado.estado
            if (ticketAnulado.seguimiento && ticketAnulado.seguimiento.length > 0) {
              if (!ticket.seguimiento) ticket.seguimiento = []
              ticket.seguimiento.push(ticketAnulado.seguimiento[ticketAnulado.seguimiento.length - 1])
            }
            if (ticketAnulado.trackingEstados && ticketAnulado.trackingEstados.length > 0) {
              if (!ticket.trackingEstados) ticket.trackingEstados = []
              ticket.trackingEstados.push(ticketAnulado.trackingEstados[ticketAnulado.trackingEstados.length - 1])
            }
            const resultado = await updateCreateTicket(ticket, 1)
            if (resultado === null) this.mensajeError(`Ocurrió un problema actualizando el ticket ${ticket.ticket}`)
          }
        }
        this.success('Anulación Tickets', 'Se anularon los tickets correctamente!!')
      }
      this.dialog = false
      this.verDialogoAnulacion = false
      await this.loadReport()
    },
    async refrescar() {
      await this.loadReport()
    },
    async anularTicket(params, isMasive = false) {
      this.isMasiveAnulation = isMasive
      const ticket = await getTicket(params.row ? params.row.id : params.id)
      if (ticket) {
        this.ticketAnular = ticket
        this.verDialogoAnulacion = true
      }
    },
    async descargarReporte(params) {
      const ticket = await getTicket(params.row.id)
      if (ticket) {
        alert('Está opción aún no está disponible!')
      }
    },
    async confirmarTicket(params) {
      const ticket = await getTicket(params.row.id)
      if (ticket) {
        alert('Está opción aún no está disponible!')
      }
    },
    async onRowClick(params) {
      const ticket = await getTicket(params.row.id)
      if (this.falla) {
        this.falla = ticket
        if (ticket.idInspeccion) this.inspeccion = await findInspeccionId(ticket.idInspeccion)
        if (ticket.idPuntoIluminacion) this.punto = await getPoste(ticket.idPuntoIluminacion)
        else {
          this.punto = {}
          if (ticket.localidad) this.punto.obj_localidad = await getLocalidad(ticket.localidad.id)
        }
        this.dialog = true
      }
    },
    async cargarFallas() {
      this.showOverlay = true
      this.tiposFallas = await getlistado(7, 'Fallas', false, this.usuario)
      if (this.tiposFallas === null) this.tiposFallas = []
      this.tiposFallas.unshift({
        nombre: 'TODAS',
        id: '',
      })
      this.showOverlay = false
    },
    async loadReport() {
      this.showOverlay = true
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
      const filter = {
        where: {
          and: [
            { companyId: this.usuario.idCompany },
            !this.detail ? {} : { fechaCreacion: { gte: fechaInicio.toISOString() } },
            !this.detail ? {} : { fechaCreacion: { lte: fechaFin.toISOString() } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.numeroPoste && this.numeroPoste !== '') {
        filter.where.and.push({ 'punto.numeroPoste': this.numeroPoste })
      }

      if (this.tipoFalla && this.tipoFalla.id !== '') {
        filter.where.and.push({ 'falla.id': this.tipoFalla.id })
      }

      if (this.etapaBusqueda && this.etapaBusqueda.value !== '') {
        filter.where.and.push({ estado: this.etapaBusqueda.value })
      }

      if (this.usuario.role === 'SUPERVISOR') {
        filter.where.and.push({ estado: { neq: 'PENDIENTE' } })
      }

      const data = await findTickets(filter, true)

      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        item.fechaCreacion = calcularFecha(item.fechaCreacion)
        // item.ruta = await getItem(8, item.rutaId)
      }
      this.showOverlay = false
      this.ticketsData = data
      this.rows = data

      this.showOverlay = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
