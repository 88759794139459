<template>
  <b-card>
    <b-tabs>
      <!--INFORMACION DEL PUNTO-->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Información del Punto</span>
        </template>
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col :cols="visibility ? '7' : '12'">
              <!-- info de poste -->
              <b-card class="card-poste">
                <b-row>
                  <b-col cols="12">
                    <h4 class="labels">
                      Información del Punto
                    </h4>
                    <br>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <label
                        label-for="noPoste"
                        class="labels"
                      >Número de Poste
                      </label>

                      <b-form-input
                        v-model="poste.numeroPoste"
                        name="noPoste"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <label
                        label-for="serieLampara"
                        class="labels"
                      >
                        Serie de Lámpara
                      </label>
                      <b-form-input
                        v-model="poste.serieLampara"
                        name="serieLampara"
                        :disabled="editable"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col :cols="!visibility ? 2 : 3">
                    <b-form-group>
                      <label
                        label-for="grupo"
                        class="labels"
                      > Grupo </label>
                      <v-select
                        v-model="poste.objGrupo"
                        label="nombre"
                        :options="grupos"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col :cols="!visibility ? 2 : 3">
                    <b-form-group>
                      <label
                        label-for="potencia"
                        class="labels"
                      >
                        Potencia
                      </label>
                      <b-form-input
                        v-model="poste.potencia"
                        name="potencia"
                        :disabled="editable"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <label
                        label-for="tipoLuminaria"
                        class="labels"
                      >
                        {{ !visibility ? "Tecnología " : "" }}Luminaria
                      </label>
                      <b-form-input
                        v-model="poste.tipoLuminaria"
                        name="tipoLuminaria"
                        :disabled="editable"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col :cols="!visibility ? 6 : 5">
                    <b-form-group>
                      <label
                        label-for="tecnologiaLampara"
                        class="labels"
                      >Tecnología Lampara</label>
                      <v-select
                        v-model="poste.tecnologiaLampara"
                        label="nombre"
                        :options="tecnologias"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col :cols="!visibility ? 6 : 5">
                    <b-form-group>
                      <label
                        label-for="modeloLampara"
                        class="labels"
                      >Modelo Lampara
                      </label>
                      <v-select
                        v-model="poste.modeloLampara"
                        label="nombre"
                        :options="modelos"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="calleAvenida"
                        class="labels"
                      >
                        Calle / Avenida
                      </label>
                      <b-form-input
                        v-model="poste.calleAvenida"
                        name="calleAvenida"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="numeral"
                        class="labels"
                      >
                        Numeral
                      </label>
                      <b-form-input
                        v-model="poste.numeral"
                        name="numeral"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="municipio"
                        class="labels"
                      >
                        Municipio
                      </label>
                      <v-select
                        v-model="poste.municipio"
                        label="nombre"
                        :options="municipios"
                        disabled
                        @input="recargarLocalidades"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="localidad"
                        class="labels"
                      >Localidad/Población</label>

                      <v-select
                        v-model="poste.localidad"
                        label="name"
                        :options="localidades"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="numeroContador"
                        class="labels"
                      >
                        Contador de Referencia/Medidor
                      </label>
                      <b-form-input
                        v-model="poste.numeroContador"
                        name="refMedidor"
                        :disabled="editable"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <label class="labels">Red de Comunicaciones</label>
                    <b-form-input
                      id="comunicaciones"
                      v-model="poste.redComunicacion"
                      placeholder=""
                      rows="2"
                      no-resize
                      :disabled="editable"
                    />
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="latitud"
                        class="labels"
                      >Latitud </label>
                      <b-form-input
                        v-model="poste.latitud"
                        name="latitud"
                        :disabled="editable"
                        type="number"
                        placeholder=""
                        class="latAzul"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label
                        label-for="longitud"
                        class="labels"
                      >
                        Longitud
                      </label>
                      <b-form-input
                        v-model="poste.longitud"
                        name="longitud"
                        :disabled="editable"
                        type="number"
                        class="longAmarillo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <label class="labels">Observaciones</label>
                    <b-form-textarea
                      id="observaciones"
                      v-model="poste.observaciones"
                      placeholder=""
                      rows="2"
                      no-resize
                      :disabled="editable"
                    />
                  </b-col>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="updateItem"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <!-- imágenes y archivos -->
            <b-col
              v-if="visibility"
              cols="5"
            >
              <b-row v-if="visibility">
                <b-col cols="12">
                  <b-card class="card-basico">
                    <b-tabs
                      justified
                      pills
                    >
                      <b-tab
                        title="Álbum de Imágenes"
                        active
                      >
                        <b-row>
                          <b-col cols="12">
                            <Carousel
                              class="card-carrusel"
                              :files="imagenes"
                            />
                          </b-col>
                        </b-row>
                      </b-tab>
                      <b-tab
                        title="Administrar Imagénes"
                        :disabled="editable"
                      >
                        <files
                          :origen="`postes/${this.poste.id}`"
                          :title="'Agregar Nueva Imágen'"
                          :type="'images'"
                          :files="poste.imagenes"
                          @update-origen="updateImagenes"
                        />
                      </b-tab>
                      <b-tab title="Documentos">
                        <files
                          :origen="`postes/${this.poste.id}`"
                          :title="'Agregar Nuevo Archivo'"
                          :type="'files'"
                          :files="poste.files"
                          @update-origen="updateFiles"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="visibility">
            <b-col cols="12">
              <b-card class="card-poste">
                <b-tabs pills>
                  <b-tab
                    title="Tickets"
                    active
                  >
                    <tickets :numero-poste="poste.numeroPoste" />
                  </b-tab>
                  <b-tab
                    v-if="user.role === 'ADMIN'"
                    title="Mantenimientos"
                  >
                    <mantenimientos :numero-poste="poste.numeroPoste" />
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
      <!--DETALLE DEL PUNTO-->
      <b-tab v-if="poste.detalle">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Detalle del Punto</span>
        </template>
        <div>
          <levantamiento-de-datos
            v-if="poste"
            key="detalle-poste"
            :is-new="false"
            :punto-iluminacion="poste"
            :is-read-only="false"
          />
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import LevantamientoDeDatos from '@/components/PuntosIluminacion/LevantamientoDeDatos.vue'
import {
  BFormTextarea,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import vSelect from 'vue-select'
import { getPoste, updateCreatePoste } from '@/utils/postes'
import { getLocalidades, getMunicipios } from '@/utils/localizacion'
import { getlistado } from '@/utils/catalogos'
import { isNumber } from '@vueuse/core'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Carousel from '@/components/Carousel.vue'
import Files from '@/components/Files.vue'
import Mantenimientos from '@/components/PuntosIluminacion/Mantenimientos.vue'
import Tickets from '@/components/PuntosIluminacion/Tickets.vue'

export default {
  components: {
    LevantamientoDeDatos,
    Files,
    Tickets,
    Mantenimientos,
    Carousel,
    BForm,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BButton,
    vSelect,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Punto de Iluminación',
      visibility: router.currentRoute.params.action !== 'Create',
      edit: router.currentRoute.params.action === 'Edit',
      localidades: [],
      idURL: router.currentRoute.params.id,
      municipios: [
        {
          id: 15,
          nombre: 'VILLA NUEVA',
          codigoRenap: '0115',
          active: true,
          idDepartamento: 1,
        },
        {
          id: 8,
          nombre: 'MIXCO',
          codigoRenap: '0108',
          active: true,
          idDepartamento: 1,
        },
      ], // TODO de momento solo se tiene villa nueva luego se deberia de cargar este catalgo
      grupos: [],
      modelos: [],
      tecnologias: [],
      imagenes: [],
      documentos: [],
      poste: {
        id: '',
        numeroContador: '',
        numeroPoste: '',
        localidad: null,
        calleAvenida: '',
        numeral: '',
        serieLampara: '',
        tipoLuminaria: '',
        potencia: '',
        latitud: '',
        longitud: '',
        detalle: null,
        historialDetalle: [],
        company: JSON.parse(localStorage.getItem('userData')).idCompany,
        municipio: {
          id: 15,
          nombre: 'VILLA NUEVA',
          codigoRenap: '0115',
          active: true,
          idDepartamento: 1,
        },
        tecnologiaLampara: null,
        modeloLampara: null,
        grupo: null,
        redComunicacion: '',
        observaciones: '',
        imagenes: [],
        files: [],
      },
      detallePunto: {
        propiedadPoste: null,
        tipoPoste: null,
        tamañoPoste: null,
        observacionesPoste: null,
        brazos: [
          {
            tipo: null,
            tamaño: null,
            estado: null,
          },
          {
            tipo: null,
            tamaño: null,
            estado: null,
          },
        ],
        lamparas: [
          {
            tecnologia: null,
            potencia: '',
            estado: null,
            modelo: '',
          },
          {
            tecnologia: null,
            potencia: '',
            estado: null,
            modelo: '',
          },
        ],
        cable: {
          estado: null,
          tipo: '',
        },
      },
      estadosBrazo: [],
      tamañosBrazo: [],
      estadosLampara: [],
      tecnologiasLampara: [],
      propiedadesPoste: [],
      tamañosPoste: [],
      tiposPoste: [],
      observacionesPoste: [],
      tiposBrazo: [],
      estadosCable: [],
      user: JSON.parse(localStorage.getItem('userData')),
      editable:
        router.currentRoute.params.action !== 'Edit'
        && router.currentRoute.params.action !== 'Create',
      showDetalle:
        router.currentRoute.params.id !== null
        && router.currentRoute.params.id !== undefined,
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
    }
  },
  async beforeMount() {
    await this.loadCatalogos()
    if (router.currentRoute.params.id === null) {
      // eslint-disable-next-line prefer-destructuring
      // this.poste.municipio = this.municipios.filter(muni => muni.id === 15)[0]   TODO Descomentar cuando ya se usen mas municipios
      await this.loadLocalidades()
      this.editable = false
    } else {
      await this.cargarItem()
    }
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.poste.municipio = this.municipios.filter(muni => muni.id === JSON.parse(localStorage.getItem('userData')).company.municipio)[0]
    if (!this.poste.files) this.poste.files = []
    if (!this.poste.imagenes) this.poste.imagenes = []
  },
  methods: {
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    async loadCatalogos() {
      this.municipios = await getMunicipios(false)
      this.tecnologias = await getlistado(
        4,
        'Tecnogología Luminarias',
        false,
        this.user,
      )
      this.modelos = await getlistado(3, 'Modelos', false, this.user)
      this.grupos = await getlistado(12, 'Grupos', false, this.user)
    },
    async listImagenes() {
      this.imagenes = []

      if (this.poste.imagenes) {
        this.poste.imagenes.forEach(imagen => {
          const urlImagen = this.user.company.enabledReport ? imagen.url : ''
          this.imagenes.push(urlImagen)
        })
      }
    },
    async loadLocalidades() {
      this.localidades = await getLocalidades(
        false,
        isNumber(this.poste.municipio)
          ? this.poste.municipio
          : this.poste.municipio.id,
      )
    },
    async cancel() {
      if (
        router.currentRoute.params.action === 'Edit'
        || router.currentRoute.params.action === 'Create'
      ) await this.$router.replace('/puntos-iluminacion')
      else await this.cargarItem()
    },
    async recargarLocalidades() {
      this.poste.localidad = null
      await this.loadLocalidades()
    },
    async updateFiles(data) {
      if (!this.poste.files) this.poste.files = []

      if (data.opcion === 'ADD') {
        this.poste.files.push(data.file)
      } else {
        this.poste.files.splice(data.index, 1)
      }
      this.updateItem(false)
    },
    async updateImagenes(data) {
      if (!this.poste.imagenes) this.poste.imagenes = []

      if (data.opcion === 'ADD') {
        this.poste.imagenes.push(data.file)
      } else {
        this.poste.imagenes.splice(data.index, 1)
      }
      this.updateItem(false)
      this.listImagenes()
    },
    async updateItem(redirect = true) {
      let result
      const punto = { ...this.poste }

      if (punto.municipio !== null) punto.municipio = punto.municipio.id
      if (punto.localidad !== null) punto.localidad = punto.localidad.id
      punto.grupo = punto.objGrupo.id
      if (punto.modeloLampara !== null) punto.modeloLampara = punto.modeloLampara.id
      else delete punto.modeloLampara

      if (!punto.observaciones) delete punto.observaciones
      if (!punto.idAmpliacion) delete punto.idAmpliacion
      if (!punto.inspecciones) punto.inspecciones = []

      if (punto.tecnologiaLampara !== null) punto.tecnologiaLampara = punto.tecnologiaLampara.id
      if (punto.potencia !== null) punto.potencia = Number(punto.potencia)
      const mensaje = router.currentRoute.params.action === 'Edit' ? 'Actualizado' : 'Creado'
      if (router.currentRoute.params.action === 'Edit') result = await updateCreatePoste(punto, 1)
      else result = await updateCreatePoste(punto, 2)
      if (result !== null && redirect) {
        this.$router.replace('/puntos-iluminacion').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} ${mensaje}!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} ${mensaje} correctamente!`,
            },
          })
        })
      } else if (redirect) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no ${mensaje}`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no ${mensaje}!`,
          },
        })
      } else {
        await this.cargarItem()
      }
    },
    async cargarItem() {
      try {
        const result = await getPoste(router.currentRoute.params.id)
        if (result !== null) {
          this.poste = result
          if (!this.poste.files) this.poste.files = []
          if (!this.poste.imagenes) this.poste.imagenes = []

          if (this.poste !== null) {
            if (this.municipios.length > 0 && this.poste.municipio) {
              // eslint-disable-next-line prefer-destructuring
              this.poste.municipio = this.municipios.filter(
                muni => muni.id === this.poste.municipio,
              )[0]
              await this.loadLocalidades()
            }
            if (!this.poste.imagenes) this.poste.imagenes = []
            if (!this.poste.files) this.poste.files = []
            await this.listImagenes()
            // eslint-disable-next-line prefer-destructuring
            this.poste.localidad = this.localidades.filter(
              localidad => localidad.id === this.poste.localidad,
            )[0]

            if (this.tecnologias.length > 0 && this.poste.tecnologiaLampara) {
              // eslint-disable-next-line prefer-destructuring
              this.poste.tecnologiaLampara = this.tecnologias.filter(
                obj => obj.id === this.poste.tecnologiaLampara,
              )[0]
            }

            if (this.modelos.length > 0 && this.poste.modeloLampara) {
              // eslint-disable-next-line prefer-destructuring
              this.poste.modeloLampara = this.modelos.filter(
                obj => obj.id === this.poste.modeloLampara,
              )[0]
            }
          }
          /* if (this.item.active === undefined) this.item.active = true
          this.item.active = this.item.active ? {
            title: 'ACTIVO',
            icon: 'ToggleRightIcon',
            value: true,
          } : {
            title: 'INACTIVO',
            icon: 'ToggleLeftIcon',
            value: false,
          } */
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
  padding: 16px;
}

.card-basico {
  border: 1px solid rgb(238, 238, 238);
}
.card-poste .text {
  color: #211f1f70;
}

.card-carrusel {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
  padding: 16px;
  width: 100%;
  color: #211f1f70;
}

.card-poste.card-azul {
  background-color: #eef1f6;
}
.labels {
  color: #376fa8;
}
</style>
